@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.animate-fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 2s;
}
