@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  body {
    @apply font-[Raleway];
  }

  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.content-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.content-div:hover::before {
  opacity: 0.2;
}

.content-div.meenCutz::before {
  background-image: url("./assets/meen.png");
  /* opacity: 0.06; */
}

.content-div.mp3Converter::before {
  background-image: url("./assets/Youtube.png");
}

.content-div.meetApp::before {
  background-image: url("./assets/Meet.png");
}

.content-div.myFlix::before {
  background-image: url("./assets/myFlix.png");
}

.content-div.pokeDex::before {
  background-image: url("./assets/Pokedex.png");
}

@media (max-width: 768px) {
  .content-div {
    height: 250px;
    width: 312px;
  }
  .skills-section {
    padding-bottom: 900px;
    padding-top: 200px;
    height: 1000px;
  }
  .contact-page {
    padding-top: 500px;
    height: 1200px;
  }
  .about-section {
    padding-bottom: 600px;
    padding-top: 200px;
  }
  .work-section {
    padding-bottom: 300px;
    padding-top: 400px;
  }
  .home-screen {
    padding-bottom: 200px;
  }
  .modals {
    width: 182vw;
    margin-left: -40vw;
  }
}
